
  export const style={
    inpuStyle:{
      colorInput:"#007336"
    },
    url:{
      urlClient:"https://serquip.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/serquip-logo-primary.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: true,
       autoRegister: false,
       recaptchaVersion : "V1"
    },
    CurrencyName:{
      name:"Bomboninha/Bomboninhas"
    },
    teamName:{
      name:"Serquip mg"
    },
    hasToken: false,
    hostHeader: "serquip"
  }
